import { useQuery } from '@tanstack/react-query'

import AuthService from '@/shared/services/auth.service'
import { IPaginationData } from '@/shared/services/types/global.types'

export const useGetUsers = (params: IPaginationData) => {
	return useQuery(
		['get users list', params],
		() => AuthService.getUsersList(params),
		{
			select: ({ data }) => {
				return data
			},
			retry: 0,
			keepPreviousData: true
		}
	)
}
