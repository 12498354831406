import {
	useGameBySport,
	useMarketList,
	useSearchMath,
	useSportList,
	useTournamentsBySport
} from '@/entities/sport/api'
import { FormWrapper, Input, ReactSelect } from '@/shared/ui/form'
import { MyOptionType } from '@/shared/ui/form/form.interface'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { updateSelectOptions } from '../../helpers'
import styles from './HeadingForm.module.scss'
import { useAnalyticDetail } from '../../api'
import { useTextLocality, useTranslationField } from '@/shared/hooks'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { debounce } from 'lodash'
import { FormattedDate, FormattedTime } from 'react-intl'

interface IProps {
	setHeadForm: React.Dispatch<React.SetStateAction<FieldValues>>
	form: UseFormReturn<FieldValues, any, undefined>
}
export const HeadingAnalyticForm: FC<IProps> = ({ setHeadForm, form }) => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { analytic_id: id } = useParams()
	const { data } = useAnalyticDetail(id!)
	const { getMessage } = useTextLocality()
	const { getField } = useTranslationField()

	const [options, setOptions] = useState<MyOptionType[]>([])
	const [optionsTournament, setOptionsTournament] = useState<MyOptionType[]>([])
	const [optionsGame, setOptionsGame] = useState<MyOptionType[]>([])
	const [optionsMatch, setOptionsMatch] = useState<MyOptionType[]>([])

	const {
		formState: { errors }
	} = form

	const sportId = form.watch('sportId')
	const [text, setText] = useState('')
	const [search, setSearch] = useState('')
	const tournamentId = form.watch('tournamentId')
	const gameId = form.watch('gameId')
	const marketId = form.watch('marketId')
	const price = form.watch('price')
	const rating_buy = form.watch('rating_buy')

	// const onSetSearchGames = useCallback(
	// 	debounce(value => {
	// 		setSearch(value)
	// 	}, 700),
	// 	[]
	// )

	// const { data: sportData, isFetching: isFetchingSport } = useSportList(10000)
	// const { data: tournamentData, isFetching: isFetchingTournament } =
	// 	useTournamentsBySport(sportId!, 21)

	// const { data: gamesData, isFetching: isFetchingGame } = useGameBySport(
	// 	tournamentId!,
	// 	21
	// )

	// const { data: searchMath, isFetching: isFetchingSearch } =
	// 	useSearchMath(search)

	// const { data: matchData, isFetching: isFetchingMatch } = useMarketList(
	// 	gameId!
	// )

	// useEffect(() => {
	// 	if (data) setSearch(data?.game.opponent_one_name_en)
	// }, [data])

	useEffect(() => {
		if (!data) return
		form.reset({
			...form.getValues()
			// sportId: String(data.game.tournament.sportId.id),
			// tournamentId: String(data.game.tournament_id),
			// gameId: String(data.game.main_const_sport_event_id),
			// marketId: String(data.market[0].pk),
			// price: data.price,
			// rating_buy: data.rating_buy
		})
	}, [data])

	return (
		<div className={styles.wrapper}>
			<div className={styles.infoEvent}>
				<div className={styles.game}>
					<div className={styles.opponent}>
						<div className={styles.image}>
							<img src={data?.game.opponent_one_image} alt='opponent' />
						</div>
						<div className={styles.name}>
							{getField(data?.game, 'opponent_one_name')}
						</div>
					</div>
					<div className={styles.versus}>VS</div>
					<div className={styles.opponent}>
						<div className={styles.image}>
							<img src={data?.game.opponent_two_image} alt='opponent' />
						</div>
						<div className={styles.name}>
							{getField(data?.game, 'opponent_two_name')}
						</div>
					</div>
				</div>
				{data?.game?.start_date && (
					<div className={styles.info}>
						<span>
							<FormattedTime value={new Date(data?.game?.start_date)} />
						</span>
						<FormattedDate value={new Date(data.game?.start_date)} />
					</div>
				)}
			</div>
		</div>
	)

	return (
		<>
			<FormWrapper
				onSubmit={val => console.log({ val })}
				className={clsx('generalFormBlock', styles.form)}
				methods={form}
			>
				<ReactSelect
					containerClassName={styles.select}
					connect
					name='sportId'
					options={options}
					// isLoading={isFetchingSport}
					label={getMessage('sport')}
					required
					errorText={errors?.['sportId']?.message}
					// registerConfig={{
					// 	required: messages[locale].required_field
					// }}
				/>
				<ReactSelect
					containerClassName={styles.select}
					connect
					name='tournamentId'
					options={optionsTournament}
					// isLoading={isFetchingTournament}
					label={getMessage('match')}
					required
					errorText={errors?.['tournamentId']?.message}
					// registerConfig={{
					// 	required: messages[locale].required_field
					// }}
				/>
				<ReactSelect
					containerClassName={styles.select}
					isSearchable
					onInputChange={(e, meta) => {
						if (!['input-blur', 'menu-close'].includes(meta.action)) {
							setText(e)
							// onSetSearchGames(e)
						}
					}}
					inputValue={text}
					connect
					name='gameId'
					options={optionsGame}
					// isLoading={isFetchingSearch}
					label={getMessage('games')}
					required
					errorText={errors?.['gameId']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<ReactSelect
					connect
					containerClassName={styles.select}
					name='marketId'
					options={optionsMatch}
					// isLoading={isFetchingMatch}
					label={getMessage('market')}
					required
					errorText={errors?.['marketId']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<Input
					name={'price'}
					type='number'
					connect
					placeholder={'0'}
					label={getMessage('price')}
					required={true}
					errorText={errors?.['price']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
					onBlur={() => {
						setHeadForm(prev => ({
							...prev,
							price: form.watch('price')
						}))
					}}
				/>
				<Input
					name={'rating_buy'}
					type='number'
					connect
					placeholder={'0'}
					label={getMessage('rating_read_text')}
					required={true}
					errorText={errors?.['rating_buy']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
					onBlur={() => {
						setHeadForm(prev => ({
							...prev,
							rating_buy: form.watch('rating_buy')
						}))
					}}
				/>
			</FormWrapper>
		</>
	)
}
