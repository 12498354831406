import { useAlertMessage } from '@/shared/hooks'
import NewsService from '@/shared/services/news.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface IDataMutate {
	id: number
	isActive: boolean
}

export const useSwitchStatusNew = () => {
	const client = useQueryClient()
	const { sendAlertError } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['switch status new'],
		(data: IDataMutate) => NewsService.switchVisibility(data.id, data.isActive)
	)

	const switchStatusNews = async (
		id: number,
		isActive: boolean,
		callback?: () => void
	) => {
		mutateAsync({ id, isActive })
			.then(res => {
				client.invalidateQueries({ queryKey: ['news list'] })
				callback?.()
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}

	return { isLoading, isError, mutate, switchStatusNews }
}
