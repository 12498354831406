import { InputCheckbox } from '@/shared/ui/input-checkbox'
import { DeleteIconJSX } from '@/shared/assets/common/svg'
import { useTextLocality, useTranslationField } from '@/shared/hooks'
import { IGame } from '@/shared/services/types/analytics.types'
import { Button, Checkbox } from '@/shared/ui/form'
import clsx from 'clsx'
import { FC } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'

import styles from './AnalyticSchoolCard.module.scss'

interface IProps {
	setConfirmationDeletion: React.Dispatch<React.SetStateAction<boolean>>
	setAnalyticsID: React.Dispatch<React.SetStateAction<number | undefined>>
	text: string
	game: IGame
	id: number
	image: string
	isLine: boolean
	checkedCard: boolean
	onChecked: (id: number) => void
	link?: string
	title?: boolean
}

export const AnalyticSchoolCard: FC<IProps> = ({
	id,
	image,
	text,
	game,
	setConfirmationDeletion,
	isLine,
	setAnalyticsID,
	checkedCard,
	onChecked,
	link,
	title
}) => {
	const { getField } = useTranslationField()
	const { getMessage } = useTextLocality()

	return (
		<div className={clsx(styles.wrapper, isLine && styles.isLineWrapper)}>
			<Checkbox
				name='selectAll'
				modifier={['red']}
				checked={checkedCard}
				onChange={() => onChecked(id)}
			/>
			<div className={styles.leftBlock}>
				<div className={styles.imageWrapper}>
					<div className={styles.opponentInfo}>
						<div className={styles.image}>
							<img src={game.opponent_one_image} alt='' />
						</div>
						<span> -vs- </span>

						<div className={styles.image}>
							<img src={game.opponent_two_image} alt='' />
						</div>
					</div>
				</div>
				<div className={styles.groupButton}>
					<Button
						modifiers={['inverse']}
						link={link || `/content/analytics/edit/${id}`}
						svg={<DeleteIconJSX />}
					>
						{getMessage('edit')}
					</Button>
					<Button
						modifiers={['inverse']}
						svg={<DeleteIconJSX />}
						onClick={() => {
							setAnalyticsID(id)
							setConfirmationDeletion(true)
						}}
					>
						{getMessage('delete')}
					</Button>
				</div>
			</div>
			<div className={styles.info}>
				<div className={styles.date}>
					<FormattedTime value={new Date(game.start_date)} />{' '}
					<FormattedDate value={new Date(game.start_date)} />
				</div>
				<div className={styles.title}>{title}</div>
				{text && (
					<div
						className={styles.description}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				)}

				<div className={styles.description}>
					{getField(game, 'opponent_one_name') +
						' -vs- ' +
						getField(game, 'opponent_two_name')}
				</div>
			</div>
		</div>
	)
}
