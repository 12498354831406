import { ControlProps, GroupBase } from 'react-select'
import { MyOptionType, TStylesSelector } from '../form.interface'

type TState = ControlProps<
	MyOptionType<string>,
	false,
	GroupBase<MyOptionType<string>>
>

export const customStyles: (
	isError?: boolean,
	mod?: { simple: boolean; circle: boolean }
) => TStylesSelector = (
	isError?: boolean,
	mod?: { simple: boolean; circle: boolean }
) => {
	const controlBackground = (state?: TState) => {
		if (mod?.simple) return '#fff'
		return '#F4F4F9'
	}

	const controlBoxShadow = (state?: TState) => {
		if (mod?.simple) return '0px 2px 6px 2px rgba(0, 0, 0, 0.08)'
		return 'none'
	}

	const controlBorderRadius = (state?: TState) => {
		if (mod?.simple && state?.selectProps.menuIsOpen && !mod?.circle)
			return '3px 3px 0 0'
		if (mod?.simple && !mod?.circle) return '3px'
		if (mod?.circle && state?.selectProps.menuIsOpen) return '18px 18px 0 0'
		if (mod?.circle) return '53px '
		return 'none'
	}

	const valueContainerPadding = (state?: TState) => {
		if (mod?.simple) return '8px 10px'
		return '12px 20px'
	}
	const valueContainerFontSize = (state?: TState) => {
		if (mod?.simple) return '14px'
		return '13px'
	}
	const menuTop = (state?: TState) => {
		if (mod?.simple) return 'auto'
		return '120%'
	}

	const menuBoxShadow = (state?: TState) => {
		if (mod?.simple) return '0px 4px 5px rgba(0, 0, 0, 0.08)'
		return 'none'
	}

	const menuBorderRadius = (state?: TState) => {
		if (mod?.simple && !mod.circle) return '0 0 3px 3px'
		if (mod?.circle) return '0 0 18px 18px '

		return '0 0 10px 10px'
	}

	const menuPaddingBottom = (state?: TState) => {
		if (mod?.simple) return '0)'
		return '15px'
	}

	const styles = {
		control: (provided, state) => ({
			...provided,
			background: controlBackground(),
			boxShadow: controlBoxShadow(),
			color: '#676C79',
			minHeight: 34,
			height: 34,
			cursor: 'pointer',
			border: 'none',
			flexWrap: 'nowrap',
			borderRadius: controlBorderRadius(state),
			'&:hover': {
				borderColor: 'transparent !important'
			}
		}),
		container: (provided, state) => ({
			...provided,
			border: isError ? '1px solid #DC0000;' : 'auto',
			width: '100%',
			height: 41
		}),
		valueContainer: (provided, state) => ({
			...provided,
			height: '34px',
			padding: valueContainerPadding(),
			overflow: 'visible',
			alignContent: 'center',
			color: '#676C79',
			fontSize: valueContainerFontSize(),
			maxWidth: 190,
			width: '100',
			overflowX: 'hidden'
			// opacity: '0.5'
		}),
		singleValue: provided => ({
			...provided,
			margin: 0
		}),
		menu: provided => ({
			...provided,
			maxHeight: '190px',
			zIndex: '100',
			marginTop: 0,
			top: menuTop(),
			boxShadow: menuBoxShadow(),
			borderRadius: menuBorderRadius(),
			paddingBottom: menuPaddingBottom(),
			overflow: 'hidden'
		}),

		menuList: provided => ({
			...provided,
			padding: '0',
			maxHeight: '110px',
			background: '#F4F4F9',
			zIndex: '100',
			'&::-webkit-scrollbar': {
				width: '5px',
				height: '5px'
			},
			'&::-webkit-scrollbar-track': {
				borderRadius: '10px',
				backgroundColor: '#bebebe69 '
			},
			'&::-webkit-scrollbar-thumb': {
				borderRadius: '10px',
				background: '#ec620f'
			}
		}),

		dropdownIndicator: (provided, state) => ({
			...provided,
			transition: ' all 0.3s ease 0s',
			transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : ''
		}),
		placeholder: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -30 : '0%',
			transition: 'top 0.2s, font-size 0.2s',
			fontSize: 13,
			opacity: '0.5'
		})
	} as TStylesSelector
	return styles
}
