import { RouterProvider } from 'react-router-dom'

import MainProvider from './providers'
import { router } from './providers/RouterProviders'

const AppRoot = () => {
	return (
		<MainProvider>
			<RouterProvider router={router} />
		</MainProvider>
	)
}

export default AppRoot
