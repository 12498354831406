import clsx from 'clsx'
import { FC } from 'react'
import Select from 'react-select'

import { ConnectSelect } from '../form-connect'
import { IPropsSelect, TPropsComponent } from '../form.interface'

import styles from './Select.module.scss'
import { customStyles } from './select-styles'

interface IMyOptionsProps extends TPropsComponent {
	mod: { simple: boolean; circle: boolean }
}

const MyOption = (props: IMyOptionsProps) => {
	const { innerProps, innerRef, isSelected, mod } = props
	if (mod.simple && isSelected) return null
	return (
		<div
			ref={innerRef}
			{...innerProps}
			className={clsx(
				styles.option,
				mod.simple && styles.simple,
				mod.circle && styles.circle,
				isSelected && styles.active
			)}
		>
			{!mod.simple && <span className={styles.check} />}

			<div className={styles.name}>{props.data.label} </div>
		</div>
	)
}

export const ReactSelect: FC<IPropsSelect> = ({
	selectRef,
	value,
	onChange,
	options,
	connect,
	defaultValue,
	name,
	containerClassName,
	registerConfig,
	label,
	required,
	isLoading,
	modifier,
	errorText,
	onInputChange,
	isSearchable = false,
	inputValue
}) => {
	const SelectComponent = connect ? ConnectSelect : Select

	const mod = {
		simple: false,
		circle: false
	}

	modifier?.forEach(item => {
		mod[item] = true
	})

	return (
		<div className={clsx(styles.wrapper, containerClassName)}>
			{label && (
				<label htmlFor={name} className={styles.label}>
					<span>
						{label} {required && '*'}
					</span>
				</label>
			)}
			<SelectComponent
				options={options}
				styles={customStyles(!!errorText, mod)}
				value={value}
				ref={selectRef}
				onChange={onChange}
				classNamePrefix={'react-select'}
				isLoading={isLoading}
				name={name}
				isSearchable={isSearchable}
				onInputChange={onInputChange}
				inputValue={inputValue}
				components={{
					IndicatorSeparator: () => null,
					Option: props => <MyOption {...props} mod={mod} />
				}}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
			/>

			{errorText && typeof errorText === 'string' && (
				<div className={styles.error}>
					<span>{errorText}</span>
				</div>
			)}
		</div>
	)
}
