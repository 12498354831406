import { InfiniteData } from '@tanstack/react-query'

/**
 * Возвращает массив элементов с бесконечной загрузки страницы
 * @param data принимает обьект полученные с бесконечной загрузки страницы
 * */
export const sortDataInfinityPages = (data?: InfiniteData<any>) => {
	if (!data) return []
	const result =
		data?.pages.reduce((acc, page) => {
			const res = page.filter((item: any) => {
				return acc.every((v: any) => v.id !== item.id)
			})
			return [...acc, ...res]
		}, []) || []
	return result
}
