import { useAlertMessage } from '@/shared/hooks'
import AnalyticsService from '@/shared/services/analytics.service'
import { IAnalytic } from '@/shared/services/types/analytics.types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useActionExperts = () => {
	const navigate = useNavigate()
	const { sendAlertError } = useAlertMessage()
	const client = useQueryClient()

	const onDeleteExpert = useMutation(
		['delete new'],
		(id: number) => AnalyticsService.delete(id),
		{
			onSuccess: () => {
				client.invalidateQueries({ queryKey: ['get experts list'] })
			},
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)

	const onCreateExpert = useMutation(
		['create new'],
		(data: IAnalytic.CreateExpert) => AnalyticsService.createExpert(data),
		{
			onSuccess: () => {
				client.invalidateQueries({ queryKey: ['get experts list'] })
				navigate('/content/experts')
			},
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)
	const onEditExpert = useMutation(
		['edit new'],
		(data: Partial<IAnalytic.ExpertData>) => AnalyticsService.editExpert(data),
		{
			onSuccess: () => {
				client.invalidateQueries({ queryKey: ['get experts list'] })
				navigate('/content/experts')
			},
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)

	return { onDeleteExpert, onEditExpert, onCreateExpert }
}
