import { axiosInstance } from '@/shared/api'
import { IPaginationData } from './types/global.types'

import { getAnalyticsUrl } from '../api/config'
import {
	IAnalytic,
	IAnalytics,
	ICreateAnalyticResponse
} from './types/analytics.types'
import { addYears, format, startOfYear } from 'date-fns'
import { toFormData } from 'axios'
import { DateTime } from 'luxon'

const currentDate = new Date()
// Прибавить один год к текущей дате
const nextYear = addYears(currentDate, 1)

const utcDate = DateTime.now().toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")

// Получить начало следующего года
const startOfNextYear = startOfYear(nextYear)

const AnalyticsService = {
	async getAll({
		page = 1,
		count = 21,
		fromDate = utcDate,
		toDate = format(startOfNextYear, `yyyy-MM-dd'T'HH:mm:ss`),
		author_name
	}: IAnalytic.IGetParams) {
		return axiosInstance.get<IAnalytic.Data[]>(getAnalyticsUrl(`/list`), {
			params: {
				page,
				count,
				fromDate,
				toDate,
				author_name
			}
		})
	},

	async getById(id: number) {
		return axiosInstance.get<IAnalytic.Data>(getAnalyticsUrl(`/get/${id}`))
	},

	async buy(id: number) {
		return axiosInstance.get<string>(getAnalyticsUrl(`/buy/${id}`))
	},

	async create(data: FormData) {
		return axiosInstance.post<ICreateAnalyticResponse>(
			getAnalyticsUrl(`/new`),
			data
		)
	},

	async edit(id: number, data: Partial<FormData>) {
		return axiosInstance.patch<string>(getAnalyticsUrl(`/edit/${id}`), data)
	},

	async delete(id: number) {
		return axiosInstance.delete<string>(getAnalyticsUrl(`/delete/${id}`))
	},

	//Experts

	async getExpertsList(data?: IAnalytic.GetExperts) {
		return axiosInstance.get<IAnalytic.ExpertData[]>(
			getAnalyticsUrl(`/expert_by_rating_name`),
			{
				params: data
			}
		)
	},

	async getExpert(id?: number) {
		return axiosInstance.get<IAnalytic.ExpertData>(
			getAnalyticsUrl(`/get_expert/${id}`)
		)
	},

	async createExpert(data: IAnalytic.CreateExpert) {
		return axiosInstance.post<IAnalytic.ExpertData>(
			getAnalyticsUrl(`/create_expert`),
			toFormData(data)
		)
	},

	async editExpert(data: Partial<IAnalytic.ExpertData>) {
		return axiosInstance.post<IAnalytic.ExpertData>(
			getAnalyticsUrl(`/edit_expert/${data.id}`),
			toFormData(data)
		)
	},

	async deleteExpert(id?: number) {
		return axiosInstance.delete<IAnalytic.ExpertData>(
			getAnalyticsUrl(`/get_expert/${id}`)
		)
	},

	async getRattingList(data?: IPaginationData) {
		return axiosInstance.get<IAnalytic.RatingData[]>(
			getAnalyticsUrl(`/rating_names_list`),
			{
				params: data
			}
		)
	}
}

export default AnalyticsService
