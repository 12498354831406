import {
	Button,
	EditorFields,
	FormWrapper,
	Input,
	InputImage
} from '@/shared/ui/form'
import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Loader } from '@/shared/ui/loader'
import { useTextLocality } from '@/shared/hooks'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { useGetExpertDetail } from '@/features/experts/api'
import { IAnalytic } from '@/shared/services/types/analytics.types'

export interface FormProps {
	lang: string
	headingPlaceholder: string
	previewPlaceholder: string
	textPlaceholder: string
	onSubmit: (values: FieldValues) => void
	short: string
	form: UseFormReturn<FieldValues, any, undefined>
}

interface IFormEditOrNew extends FormProps {
	setArticleItem: React.Dispatch<
		React.SetStateAction<IAnalytic.ExpertData | undefined>
	>
}

export const ExpertForm: FC<IFormEditOrNew> = ({
	lang,
	headingPlaceholder,
	previewPlaceholder,
	textPlaceholder,
	onSubmit,
	short,
	form,
	setArticleItem
}) => {
	const { getMessage } = useTextLocality()

	const { id } = useParams()

	const { locale } = useAppSelector(state => state.defaultReducer)
	const { data, isFetching } = useGetExpertDetail(Number(id))
	const {
		formState: { errors }
	} = form

	const defaultValues = {
		lang: short,
		typeSubmit: id ? 'expert' : 'new_expert'
	}

	const onSubmitForm = (values: FieldValues) => {
		onSubmit(values)
	}

	useEffect(() => {
		if (data) {
			form.reset({
				...defaultValues,
				...data
			})
			setArticleItem(data)
		} else {
			form.reset({
				...defaultValues
			})
		}
	}, [data])

	return (
		<>
			<FormWrapper
				className={clsx('PageEditOrNewForm')}
				methods={form}
				onSubmit={onSubmitForm}
			>
				{isFetching && <Loader absolute />}
				<div className='langTitle'>{lang}</div>
				<div className='required'>* {getMessage('required_field_tool')}</div>
				<Input
					name={`name_${short}`}
					connect
					placeholder={headingPlaceholder}
					label={getMessage('name')}
					required={!id}
					errorText={errors?.[`name_${short}`]?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<Input
					name={`category_${short}`}
					connect
					placeholder={headingPlaceholder}
					label={getMessage('category')}
					required={!id}
					errorText={errors?.[`category_${short}`]?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>

				<EditorFields
					connect
					name={`description_${short}`}
					label={getMessage('description')}
					required={!id}
					placeholder={textPlaceholder}
					errorText={errors?.[`description_${short}`]?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<div className={'row'}>
					<InputImage
						connect
						lang={lang}
						type='image/webp'
						name={'img_link'}
						required={!id}
						size='450х300'
					/>
				</div>
			</FormWrapper>
		</>
	)
}
