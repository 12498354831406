import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { IPropsRadioGroup } from '../form.interface'
import { RadioButton } from '../radio-button'

import { ConnectForm } from './ConnectForm'

export const ConnectedRadioGroup: FC<IPropsRadioGroup> = ({
	registerConfig,
	options,
	...props
}) => {
	return (
		<ConnectForm>
			{({ control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, ref, value } }) => {
						return (
							<>
								{options.map(item => (
									<RadioButton
										{...props}
										key={item.value}
										name={props.name}
										onChange={onChange}
										radioRef={ref}
										title={item.label}
										value={item.value}
										checked={item.value === value}
									/>
								))}
							</>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
