import { useQuery } from '@tanstack/react-query'

import AnalyticsService from '@/shared/services/analytics.service'
import { IAnalytic } from '@/shared/services/types/analytics.types'

export const useGetExperts = (data: IAnalytic.GetExperts) => {
	return useQuery(
		['get experts list', data],
		() => AnalyticsService.getExpertsList(data),
		{
			select: ({ data }) => data,
			retry: 0
		}
	)
}

export const useGetExpertDetail = (id: number) => {

	return useQuery(
		['get expert detail', id],
		() => AnalyticsService.getExpert(id),
		{
			select: ({ data }) => data,
			enabled: !!id,
			retry: 0
		}
	)
}
