import { useState, useEffect, FC } from 'react'
import { useLineOrTiles } from '@/shared/hooks/useLineOrTiles'
import { useTextLocality } from '@/shared/hooks'
import ContentPanel from '@/entities/content-panel'
import { useSwitchStatusNew, useDeleteNew } from '@/entities/blog/api'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@/shared/ui/loader'
import { Confirmation } from '@/shared/ui/confirmation'
import { Button } from '@/shared/ui/form'
import { AddItemJSX } from '@/shared/assets/common/svg'
import styles from './ExpertsList.module.scss'
import { useActionExperts, useGetExperts } from '@/features/experts/api'
import { ExpertsCard } from '@/entities/experts/ui/experts-card'
import { useActionData } from 'react-router-dom'

export const ExpertsList: FC = () => {
	const [selectedShop, setSelectedShop] = useState<number[]>([])
	const { onEditExpert } = useActionExperts()
	const { switchStatusNews } = useSwitchStatusNew()
	const { onDeleteNew } = useDeleteNew()
	const { getMessage } = useTextLocality()
	const onSwitchStatus = async (id: number, is_active: boolean) => {
		try {
			await onEditExpert.mutateAsync({ id, is_active })
		} catch (error) {}
	}

	const { data, isLoading } = useGetExperts({
		rating_name: '',
		count: 50,
		page: 0
	})

	const [confirmationDeletion, setConfirmationDeletion] = useState(false)
	const [newDetailId, setDetailId] = useState<number>()

	useEffect(() => {
		if (!confirmationDeletion) setDetailId(0)
	}, [confirmationDeletion])

	const { confirmationRow, setConfirmationRow } = useLineOrTiles()

	const onAlSelected = () => {
		const res = data?.map(item => item.id) || []
		if (selectedShop.length === data?.length) return setSelectedShop([])
		setSelectedShop(res)
	}

	const onAlSelectedCard = (id: number) => {
		const isActive = selectedShop.find(val => val === id)
		const newSelected = selectedShop.filter(val => val !== id)
		if (isActive) return setSelectedShop(newSelected)
		setSelectedShop([...selectedShop, id])
	}

	return (
		<>
			<ContentPanel
				confirmationRow={confirmationRow}
				setConfirmationRow={setConfirmationRow}
				checked={false}
				onChecked={onAlSelected}
				selectedItems={[]}
				newTemplateBtn={
					<Button
						modifiers={['inverse']}
						svg={<AddItemJSX />}
						link='/content/experts/new'
					>
						{getMessage('new_expert')}
					</Button>
				}
			/>
			<div className={clsx(styles.wrapperList, confirmationRow && styles.line)}>
				{data?.map(item => (
					<ExpertsCard
						isLine={confirmationRow}
						key={item.id}
						data={item}
						editLink={`/content/experts/edit/${item.id}`}
						checkedCard={!!selectedShop.find(id => id === item.id)}
						setCategoryID={setDetailId}
						detailList={false}
						activeLink={onSwitchStatus}
						status_edit={true}
					/>
				))}
			</div>

			{!isLoading && !data?.length && <FormattedMessage id='not_found' />}

			{isLoading && <Loader />}
			<Confirmation
				isOpen={confirmationDeletion}
				title={getMessage('confirm_ask')}
				setConfirmationState={() => {
					const arr = newDetailId ? [newDetailId] : selectedShop

					arr.forEach(item => {
						onDeleteNew(item)
					})
				}}
				setConfirmationOpen={setConfirmationDeletion}
			/>
		</>
	)
}
