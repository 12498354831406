import { FC, useEffect, useMemo, useState } from 'react'
import { ExpertForm } from '../expert-form'
import '@/Assets/sass/Pages/School/Form.sass'
import styles from './TotalForm.module.scss'
import { FieldValues, useForm } from 'react-hook-form'
import {
	Button,
	Checkbox,
	FormWrapper,
	Input,
	ReactSelect
} from '@/shared/ui/form'
import clsx from 'clsx'
import { useTextLocality, useTranslationField } from '@/shared/hooks'
import { typeLangForm } from '@/shared/constants.interface'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { PublishIconJSX } from '@/shared/assets/common/svg'
import { useGetRating } from '@/features/reward-system/api'
import { IAnalytic } from '@/shared/services/types/analytics.types'
import { useParams } from 'react-router-dom'

interface ITotalForm {
	languages: typeLangForm[]
	onSubmit: (val: FieldValues) => void
}

export const TotalFormExpert: FC<ITotalForm> = ({ languages, onSubmit }) => {
	const { id } = useParams()
	const { getMessage } = useTextLocality()
	const { getField } = useTranslationField()

	const [articleItem, setArticleItem] = useState<IAnalytic.ExpertData>()
	const { locale } = useAppSelector(state => state.defaultReducer)
	const form = useForm({ mode: 'onChange' })

	const { data, isLoading } = useGetRating()

	const ratingOptions = useMemo(
		() =>
			data?.map(item => ({
				label: getField(item, 'name'),
				value: item.name_en
			})) || [],
		[data, getField]
	)

	const {
		formState: { errors }
	} = form

	const onSubmitForm = (values: FieldValues) => {
		const data = {
			...values,
			...(typeof values.img_link === 'string'
				? { img_link: undefined }
				: { img_link: values.img_link }),
			typeSubmit: articleItem ? 'expert' : 'new_expert'
		}

		onSubmit(data)
	}

	useEffect(() => {
		if (!articleItem) return
		form.reset({
			...articleItem
		})
	}, [articleItem])

	return (
		<div className={styles.wrapper}>
			<FormWrapper
				className={clsx('generalFormBlock', styles.form)}
				methods={form}
				onSubmit={onSubmitForm}
			>
				<div className={styles.wrapperHead}>
					<Input
						name={'raiting_int'}
						type='number'
						connect
						placeholder={'0'}
						label={getMessage('count_rating_text')}
						errorText={errors?.['raiting_int']?.message}
					/>
					<ReactSelect
						connect
						containerClassName={styles.select}
						name='rating_name'
						options={ratingOptions}
						isLoading={isLoading}
						label={getMessage('level')}
						required={!id}
						errorText={errors?.['rating_name']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>

					<Checkbox
						name='is_active'
						title={getMessage('publish_now')}
						connect
					/>
				</div>

				<div className={styles.wrapperForm}>
					{languages.map(item => (
						<ExpertForm
							key={item.lang}
							form={form}
							headingPlaceholder={item.headingPlaceholder}
							lang={item.lang}
							short={item.short}
							onSubmit={onSubmitForm}
							previewPlaceholder={item.previewPlaceholder}
							textPlaceholder={item.textPlaceholder}
							setArticleItem={setArticleItem}
						/>
					))}
				</div>
				<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish_all')}
				</Button>
			</FormWrapper>
		</div>
	)
}
