import { IPaginationData } from './types/global.types'
import Cookies from 'js-cookie'

import { axiosClassic, axiosInstance } from '@/shared/api'
import { removeTokensStorage, saveTokensStorage } from './auth.helpers'
import { getUserUrl } from '../api/config'
import {
	IAuthResponse,
	ITokens,
	IUserRating,
	IResetPasswordForm,
	IUserState,
	IUsersResponse,
	IUsersList,
	GetBettingExpert
} from './types/user.types'
import store from '../store/store'
import { IExpressList } from './types/sport.types'
import { endOfDay } from 'date-fns'

const AuthService = {
	async register(formData: FormData) {
		const response = await axiosInstance.post<string>(
			getUserUrl('/register'),
			formData
		)

		return response
	},

	async activation(email: string, code: string) {
		const response = await axiosInstance.post<IAuthResponse>(
			getUserUrl('/activate'),
			{ email, code }
		)

		if (response.data.access) saveTokensStorage(response.data)

		return response
	},

	async getTokens(formData: FormData) {
		const response = await axiosClassic.post<IAuthResponse>(
			getUserUrl('/access'),
			formData
		)

		if (response.data.access) saveTokensStorage(response.data)
		store.dispatch({
			type: 'LOGIN_SUCCESS',
			value: response.data
		})

		return response
	},

	async getNewTokens() {
		const refreshToken = Cookies.get('refreshToken')

		const response = await axiosInstance.get<ITokens>(getUserUrl('/refresh'), {
			headers: { Authorization: `Bearer ${refreshToken}` }
		})

		if (response.data.access) saveTokensStorage(response.data)

		return response
	},

	async logout() {
		removeTokensStorage()
		window.location.reload()
	},

	async getMe() {
		const response = await axiosInstance.get<IUserRating>(getUserUrl('/me'))
		return response
	},

	async resetPassword(data: IResetPasswordForm) {
		const { lang } = data
		const response = await axiosInstance.post<IUserRating>(
			getUserUrl(`/user/password/reset?lang=${lang}`),
			data.form
		)
		return response
	},

	async sendCodeResetPassword(data: FormData) {
		const response = await axiosInstance.patch<IUserRating>(
			getUserUrl('/user/password/reset/code'),
			data
		)
		return response
	},

	async getUsersList(data: IUsersList) {
		const response = await axiosInstance.get<IUsersResponse>(
			getUserUrl('/list'),
			{ params: data }
		)
		return response
	},

	async getBetsExpertsList({
		fromDate,
		toDate = new Date(),
		...restParams
	}: GetBettingExpert) {
		return axiosInstance.get<IExpressList[]>(
			getUserUrl(`/bet/expert_bets_list`),
			{
				params: {
					fromDate: fromDate?.toISOString(),
					toDate: endOfDay(toDate).toISOString(),
					...restParams
				}
			}
		)
	}
}

export default AuthService
