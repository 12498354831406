import { useEffect, useState } from 'react'

import styles from './UserDashboard.module.scss'
import { StatusTable } from '@/entities/users/ui'
import { TColumnsTable, Table } from '@/shared/ui/table'
import store from '@/shared/store/store'
import { messages } from '@/i18n/messages'
import { UsersFilter } from '@/features/users/ui'
import { useGetUsers } from '@/features/users/api'
import { RegistrationStatic } from '@/entities/user-detail/ui/registration-static'
import { Pagination } from '@/shared/ui/pagination'
import { useTextLocality } from '@/shared/hooks'
import { transformDateToString } from '@/shared/utils'
import { FieldValues, useForm } from 'react-hook-form'

interface IUsersTable {
	id: number
	name: {
		firstName: string
	}
	status: boolean
	region: string
	contact: {
		email: string
	}
}

export const UsersDashboard = () => {
	const defaultFilter = {
		page: 1,
		count: 10
	}
	const form = useForm()

	const { getMessage } = useTextLocality()
	const [filter, setFilter] = useState<any>(defaultFilter)

	const onSearchHandler = (form: FieldValues) => {
		const res = {
			...form,
			fromDate: form.fromDate && transformDateToString(form.fromDate),
			toDate: form.toDate && transformDateToString(form.toDate)
		}
		setFilter({ ...defaultFilter, ...res })
	}

	const { data, isFetching } = useGetUsers(filter)

	const [usersList, setUsersList] = useState<IUsersTable[]>([])

	useEffect(() => {
		const res =
			data?.users?.map(item => ({
				id: item.id,
				name: { firstName: item.name || '', secondName: item.name || '' },
				status: item.isActive,
				region: '-',
				contact: { email: item.email }
			})) || []

		setUsersList(res)
	}, [data])

	useEffect(() => {
		if (!data?.users.length && data?.total_users) {
			onChangePage({ selected: 0 })
		}
	}, [data])

	const columns: TColumnsTable[] = [
		{
			title: getMessage('name'),
			dataIndex: 'name',
			width: '429px',
			render: (data: any, { id }) => {
				return (
					<a href={`users/${id}`} className={styles.link}>
						<div className={styles.firstName}>{data?.firstName}</div>
						{/* <div>{data?.secondName}</div> */}
					</a>
				)
			}
		},
		{
			title: getMessage('contact'),
			dataIndex: 'contact',
			width: '761px',
			render: (data: any, { id }) => {
				return (
					<a href={`users/${id}`} className={styles.link}>
						<div>{data?.email}</div>
						<div>{data?.tel}</div>
					</a>
				)
			}
		},
		{
			title: getMessage('region'),
			width: '471px',
			dataIndex: 'region'
		},
		{
			title: getMessage('status'),
			dataIndex: 'status',
			width: '137px',
			align: 'center',
			render: (data: any) => {
				const locale = store.getState().defaultReducer.locale

				const isActive = data
				const activeText = messages[locale].active
				const inActiveText = messages[locale].inactive
				return (
					<div className={styles.status}>
						<StatusTable
							isActive={isActive}
							text={isActive ? activeText : inActiveText}
						/>
					</div>
				)
			}
		}
	]

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}
	const onChangeCountItem = (count: number) => {
		setFilter({ ...filter, count, page: 1 })
	}

	return (
		<>
			<RegistrationStatic />
			<div className={styles.filter}>
				<UsersFilter onSubmit={onSearchHandler} form={form} />
			</div>
			<div className={styles.table}>

				<Table
					isLoading={isFetching}
					columns={columns}
					dataSource={usersList}
					isChecked
				/>
			</div>
			<div className={styles.pagination}>
				<Pagination
					current={filter.page - 1}
					pageCount={(data?.total_users || 0) / filter.count}
					countItems={filter.count}
					onPageChange={onChangePage}
					setCountItems={onChangeCountItem}
				/>
			</div>
		</>
	)
}
